



























import { getMyTeams } from "@/api/teamService";
import { userModule } from "@/store/modules/user";
import useAsyncData from "@/hooks/useAsyncData";

import {
  defineComponent, onMounted,
  provide,
  reactive,
} from "@vue/composition-api";
import { getLessons } from "@/api/lessonService";
import Calendar from "@/components/Calendar.vue";
import LessonCardCustomer from "@/components/lessons/LessonCardCustomer.vue";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "LessonsCustomer",
  components: { Calendar, LessonCardCustomer },
  setup() {
    const vm = reactive({
      comingOrPast: "coming",
      lesson: {
        ID: ""
      } as Learnlink.Lesson.FullViewApp,
      showLessonCard: false,
      teams: [] as Learnlink.Team.FullMetadata[],
    });

    const { isLoading, data: lessons } = useAsyncData(() =>
      getLessons("customer", userModule.state.userId)
    );
    onMounted(async() => {
      vm.teams = await getMyTeams(userModule.state.userId);
    });

    const showLessonCard = (lesson: Learnlink.Lesson.FullViewApp): void => {
      vm.lesson = lesson;
      vm.showLessonCard = true;
    };

    provide("showLessonCard", showLessonCard);

    return {
      isLoading,
      lessons,
      showLessonCard,
      vm,
    };
  },
});
